import React, { useEffect, useRef } from "react"
import { renderElements } from '../';


const Row = props => {

    const row = useRef(null);
    console.log(props);
    const render_columns = () => {

        let output = [];

        for (var i = 1; i <= parseInt(props.columns); i++) {

            output.push(<div key={i} className={"col" + props.column_sizes['col' + (i)]}>
                <div>
                    {renderElements(props.children[(i - 1)].children)}
                </div>
            </div>);

        }

        return output;

    }

    let add = {};
    let additionalStyles = {};
    let classes = props.classes;

    if (props.sidemenu_id !== null) {
        add.id = props.sidemenu_id;
        classes.push('hasSidemenuAnchor')
    }

    if (classes.includes('bg_right')) {

    }

    useEffect(() => {

        if (classes.includes('bg_right')) {
            calcWidth();
            window.addEventListener('resize', calcWidth);

            return () => {
                if (props.transparent) {
                    window.removeEventListener("resize", calcWidth);
                }
            };
        }
    })

    const calcWidth = (e) => {

        const windowRight = window.outerWidth;
        const containerLeft = row.current.getBoundingClientRect().x;
        
        // const offsetWidth = (windowWidth - wrapperWidth) / 2;

        const newWidth = windowRight - containerLeft;

        row.current.style.width = newWidth+'px'

    }

    return (
        <div ref={row} style={{ ...props.style }} {...add} className={"container " + classes.join(' ')} >
            {render_columns()}
        </div>
    )


}


export default Row;