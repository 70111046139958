/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import Header from "../components/sections/header"
import Footer from "../components/sections/footer"
import "../scss/layout.scss"
import SocialMedia from "./sections/SocialMedia"
import SideMenu from "./sections/sidemenu"
import CookieNotice from "./elements/CookieNotice"
import { Helmet } from "react-helmet"

const Layout = ({ children, contentObjects, slug }) => {

    let transparent = false;

    if (contentObjects !== null) {
        contentObjects.forEach(element => {
            if (element.id === 'start_header') transparent = true;
        });
    }

    return (
        <>
            <Helmet>
            </Helmet>
            <Header
                transparent={transparent}
                slug={`/${slug}`}
            />
            <main
            >{children}</main>
            <Footer />
            <CookieNotice />
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
