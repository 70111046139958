import React, { useRef, useState, useEffect } from "react"
import { InlineWidget } from "react-calendly";

const Spacer = ({ link }) => {

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {

        const handleResize = () => {
            if(document.documentElement.clientWidth < 992){
                setIsMobile(true);
            }else{
                setIsMobile(false);
            }
        }

        window.addEventListener('resize', handleResize)

        return _ => {
            window.removeEventListener('resize', handleResize)

        }
    }, [])

    return (
        <InlineWidget url={link}
            styles={{
                height: isMobile ? '1300px' : '700px',
                marginTop: 30
            }} />
    );


}

export default Spacer
