import React from "react"
import * as styles from "./starthero.module.scss"
import parse from 'html-react-parser';

const StartHero = (props) => {

    return (
       <div
            className={styles.heroWrapper}
            style={{backgroundImage: `url("${props.image}")`}}
        >
            <div className={styles.overlay}>
                <div className={styles.content}>
                    <h1>
                        {parse(props.headline)}
                    </h1>
                    <div className={styles.textContainer}>
                        {parse(props.text)}
                    </div>
                </div>
            </div>



        </div>
    )
}

export default StartHero
