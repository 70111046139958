import React from "react"

const Image = (props) => {

    const img = <img src={props.url}
        style={{ width: '100%' }}
    />;

    console.log("props.link", props.link)

    if (props.link !== undefined && props.link !== null && props.link !== '') {
        return (
            <a target="_blank" href={props.link}>{img}</a>
        );
    }

    return img;

}

export default Image
