import React, { useRef, useState } from "react"
import * as styles from "./index.module.scss"
import axios from "axios"
import { Link } from "@reach/router"

const Spacer = (props) => {

    const [name, setName] = useState("");
    const [telnumber, setTelnumber] = useState("");
    const [firma, setFirma] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState(0);
    const [datenschutzCheck, setDatenschutzCheck] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (
            name != "" && email != ""
        ) {
            let person = {
                name: name,
                email: email,
                telnumber: telnumber,
                firma: firma,
            };

            window.open('https://spectory.at/3garbeitsplatz/wp-content/uploads/2022/01/Flyer_Praesentation_02.pdf', '_blank').focus();

            await axios.post("https://spectory.at/3garbeitsplatz/wp-json/siegfried/v1/contact", { person })
        }


    }

    return (
        <div className={styles.wrapper}>
            <h2>Jetzt 3G-Infoblatt mit Kostenbeispielen herunterladen</h2>
            <h4>Erfahren Sie, wie Sie in Ihrem Unternehmen die 3G/2G-Kontrollen DSGVO-konform automatisieren können.</h4>

            <form onSubmit={handleSubmit}>
                <label>
                    Name <span style={{ color: "#eb4034" }}>*</span>
                    <input placeholder="Vor- und Nachname" type="text" value={name} onChange={(event) => setName(event.target.value)} required />
                </label>
                <label>
                    Geschäftliche E-Mail <span style={{ color: "#eb4034" }}>*</span>
                    <input placeholder="ihre@mail.com" type="email" value={email} onChange={(event) => setEmail(event.target.value)} required />
                </label>
                <label>
                    Telefonnummer
                    <input placeholder="+43 123 4567890" type="tel" value={telnumber} onChange={(event) => setTelnumber(event.target.value)} />
                </label>
                <label>
                    Unternehmen
                    <input placeholder="Fiktion GmbH" type="text" value={firma} onChange={(event) => setFirma(event.target.value)} />
                </label>
                <label className={styles.checkbox}>
                    <input type="checkbox" checked={datenschutzCheck} onChange={(event) => setDatenschutzCheck(event.target.checked)} required />
                    Ich habe die <Link to="/datenschutz">Datenschutzerklärung</Link> gelesen und stimme dieser zu. <span style={{ color: "#eb4034" }}>*</span>
                </label>
                <input className={styles.btn_submit} type="submit" value="Jetzt downloaden" />
            </form>
        </div>
    );


}

export default Spacer
