import React, { useRef, useState } from "react"
import parse from 'html-react-parser';

const Spacer = (props) => {

    return (
        <div style={props.style}>
        </div>
    );


}

export default Spacer
