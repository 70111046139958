import { Link } from "@reach/router";
import { withPrefix } from "gatsby-link";
import React, { useEffect, useState } from "react"
import { validate } from "../../../services/validation";
import Button from "../Button";
import * as styles from "./contactform.module.scss"
import { sendContactForm } from "./sendContactForm";

const ContactForm = () => {

    const defaultForm = {
        name: {
            value: '',
            valid: true,
            rules: {
            }
        },
        email: {
            value: '',
            valid: false,
            rules: {
                isRequired: true,
                isEmail: true
            }
        },
        message: {
            value: '',
            valid: true,
            rules: {
            }
        },
        gdpr: {
            value: false,
            valid: false,
            rules: {
                isRequired: true,
                isTrue: true
            }
        }
    }

    const [form, setForm] = useState(
        defaultForm
    );

    const [showError, setShowError] = useState(false);
    const [formState, setFormState] = useState('ready');

    const handleChange = (name, val) => {

        if (val.target !== undefined) {
            val = val.target.value;
        }

        let valid = validate(val, form[name].rules);

        setForm(prevState => ({
            ...prevState,
            [name]: {
                value: val,
                valid: valid,
                rules: form[name].rules
            }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setShowError(false);

        let sendData = {};
        let isValid = true;

        Object.keys(form).forEach(formKey => {

            let formElement = form[formKey];

            if (!formElement.valid) {
                isValid = false;
            }

        })

        if (!isValid) {
            setShowError(true);
            return false;
        }

        setFormState('loading')

        sendContactForm(form.name.value, form.email.value, form.message.value)
            .then(res => {
                setFormState('success')
                setForm(defaultForm)
                setTimeout(() => { setFormState('ready') }, 2000)
            })
            .catch(err => {
                setFormState('error')
                setTimeout(() => { setFormState('ready') }, 2000)
            })

    }

    return (

        <form
            onSubmit={handleSubmit}
        >
            <div className="container fullwidth" style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="col6">
                    <label>
                        Name<br />
                        <input
                            type="text"
                            className={!form.name.valid && showError ? 'error' : null}
                            value={form.name.value}
                            onChange={val => handleChange('name', val)}
                        />
                    </label>
                </div>
                <div className="col6">
                    <label>
                        E-Mail-Adresse <span style={{color: '#ADAFAF'}}>(Pflichtfeld)</span><br />
                        <input
                            type="text"
                            className={!form.email.valid && showError ? 'error' : null}
                            value={form.email.value}
                            onChange={val => handleChange('email', val)}
                        />
                    </label>
                </div>
            </div>
            <div className="container fullwidth" style={{ paddingTop: 30, paddingLeft: 0, paddingRight: 0 }}>
                <div className="col12">
                    <div>
                        <label>
                            Nachricht<br />
                            <textarea
                                className={!form.message.valid && showError ? 'error' : null}
                                value={form.message.value}
                                onChange={val => handleChange('message', val)}
                            ></textarea>
                        </label>
                        <br />
                        <br />
                        <br />
                        <input
                            id="gdprCheckbox"
                            type="checkbox"
                            className={!form.gdpr.valid && showError ? 'error' : null}
                            checked={form.gdpr.value}
                            onChange={() => {
                                handleChange('gdpr', !form.gdpr.value)
                            }}
                        />
                        <label for="gdprCheckbox">
                            <div className="checkboxIcon"></div>
                            Ich habe die <u><Link style={{color: "inherit"}} to={withPrefix('/datenschutz')}>Datenschutzerklärung</Link></u> zur Kenntnis genommen und erkläre mich damit einverstanden. <span style={{color: '#ADAFAF'}}>(Pflichtfeld)</span>
                        </label>

                        <br />
                        <br />
                        <br />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                type="submit"
                                title="Absenden"
                                buttonState={formState}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>

    );


}

export default ContactForm
